const url = "https://cdn.contentful.com/spaces/fia9nl77efkl/environments/master/entries?content_type=event&access_token=hgIsQtzVU-ySLgm4yRiVQgg0TF0lHpm0AyQlZ91xJtw"

const options = {
    method: "GET",
    headers: {},
}


const  getEvents = () => {
    // const response = fetch(url, options)
    // const data = parseEvents((await response))
    // return data
    return fetch(url, options)
        .then((response) => response.json())
};

export default getEvents;