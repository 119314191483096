import {useLocation} from 'react-router-dom';
import EventCard from '../components/Event/EventCard';

const Event = () => {
  const { state } = useLocation();
  console.log("Event state", state.myEvent)
  console.log("Event title", state.myEvent.title)

  return(
      <div className="flex justify-center md:my-16">
        {/* Event {myEvent.title} */}
        <EventCard title={state.myEvent.title} description={state.myEvent.description} date={state.myEvent.JSDate}/>
      </div>
  )
}

export default Event;