import { Link } from "react-router-dom"
import { useCalendarData } from "../../contexts/calendarData"

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


const MonthlyCalendarDays = () => {
    const { myDays, changeSelectedDate } = useCalendarData()

    return (
        <div className="flex text-xs leading-6 text-gray-700 md:flex-auto">
            <div className="hidden  w-full md:grid md:grid-cols-7 md:grid-rows-6 md:gap-1">
                {myDays.map((day) => (
                    <div
                        key={day.date}
                        className={classNames(
                            !day.isCurrentMonth && 'text-gray-400',
                            'relative bg-gray-50 p-1 before:pt-[100%] before:float-left  '
                        )}
                    >
                        <time
                            dateTime={day.date.toDateString()}      // TODO: make the format correct
                            className={
                                day.isToday && 'flex h-6 w-6 items-center justify-center rounded-full bg-[#721513] font-semibold text-white'

                            }
                        >
                            {day.date.getDate()}
                        </time>
                        {day.events.length > 0 && (
                            <ol className="mt-2">
                                {day.events.slice(0, 8).map((event) => (
                                    <li key={event.id}>
                                        <Link to="/event" state={{ myEvent: event}}>
                                            <time
                                                dateTime={event.datetime}
                                                className="hidden flex-none pr-1 text-black font-bold group-hover:text-[#721513] md:block"
                                            >
                                                {new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true }).format(event.JSDate)}
                                            </time>
                                            <p className="flex-auto truncate font-medium text-gray-900 group-hover:text-[#721513]">
                                                {event.title}
                                            </p>
                                        </Link>
                                    </li>
                                ))}
                                {day.events.length > 8 && <li className="text-gray-500">+ {day.events.length - 3} more</li>}
                            </ol>
                        )}
                    </div>
                ))}
            </div>
            <div className="grid w-full grid-cols-7 grid-rows-6 gap-1 pt md:hidden">
                {myDays.map((day) => (
                    <button
                        key={day.date}
                        type="button"
                        onClick={() => changeSelectedDate(day)}
                        className={classNames(
                            day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                            (day.isSelected || day.isToday) && 'font-bold',
                            day.isSelected && 'text-white',
                            !day.isSelected && day.isToday && 'text-[#721513]',
                            !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                            !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
                            'flex  flex-col items-center py-2 px-3 bg-gray-50 hover:bg-gray-100 focus:z-10 aspect-square'
                        )}
                    >
                        <time
                            dateTime={day.date.toDateString()}
                            className={classNames(
                                day.isSelected && 'flex h-6 w-6 items-center justify-center rounded-full',
                                day.isSelected && day.isToday && 'bg-[#721513]',
                                day.isSelected && !day.isToday && 'bg-gray-900',
                                'flex h-6 w-6 items-center justify-center rounded-full'     // TODO: not sure why I can't make the date other than selected a circle also
                            )}
                        >
                            {day.date.getDate()}
                        </time>
                        <span className="sr-only">{day.events.length} events</span>
                        {day.events.length > 0 && (
                            <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                {day.events.map((event) => (
                                    <span key={event.id} className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-[#721513]" />
                                ))}
                            </span>
                        )}
                    </button>
                ))}
            </div>
        </div>
    )
}

export default MonthlyCalendarDays