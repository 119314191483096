import { addDaysForEndOfMonth, addDaysToCurrentMonth, addDaysToPreviousMonth, addEvents } from "./datesHelper"

const populateDays = (selectedMonth, selectedDate, todaysDate, events) => {
    let startOfMonth = (new Date(selectedMonth))
    startOfMonth.setDate(1)     // For some reason it doesn't like it inline when you use an existing Date in construction
    startOfMonth.setHours(0, 0, 0, 0)      // Set to all hour/minute/second/ms to 0s for easy date comparisons

    let StartOfNextMonth = (new Date(startOfMonth))
    StartOfNextMonth.setMonth(StartOfNextMonth.getMonth() + 1)

    let newMyDays = []
    addDaysToPreviousMonth(newMyDays, startOfMonth, selectedDate, todaysDate)
    addDaysToCurrentMonth(newMyDays, startOfMonth, selectedMonth, selectedDate, todaysDate)
    addDaysForEndOfMonth(newMyDays, StartOfNextMonth, selectedDate, todaysDate)

    if (events) {
        addEvents(newMyDays, events.items)
    }

    return newMyDays
}

export default populateDays;