import { NavLink } from "react-router-dom";

const NavButtonDesktop = ({ to, text }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? "border-primary-color-lighter text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"}
    >
      {text}
    </NavLink>
  );
}

export default NavButtonDesktop;