import { useEffect } from "react";
import MonthlyCalendarHeader from './MonthlyCalendarHeader';
import MonthlyCalendarLabels from './MonthlyCalendarLabels';
import MonthlyCalendarDays from './MonthlyCalendarDays';
import MonthlyCalendarList from "./MonthlyCalendarList";
import { useCalendarData } from '../../contexts/calendarData';

export default function MonthlyCalendar() {
    const { fetchEvents, events } = useCalendarData()

    useEffect(() => {
        console.log("MonthlyCalendar useEffect")
        fetchEvents()
    }, [fetchEvents])

    console.log("Monthly Calendar events", events)


    return (
        <>
            {typeof(events) !== 'undefined' &&
                <div className="lg:flex lg:h-full lg:flex-col">
                    <MonthlyCalendarHeader />
                    <div className="lg:flex lg:flex-auto lg:flex-col">
                        <MonthlyCalendarLabels />
                        <MonthlyCalendarDays />
                    </div>
                    <MonthlyCalendarList />
                </div>
            }
        </>
    )
}
