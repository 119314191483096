import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { useController } from 'react-hook-form'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RadioCardsInline({ title, optionsList, control, name }) {
  const [selection, setSelection] = useState()
  // can't just register() so need to use a controller
  const { field } = useController({
    control,
    name,
    defaultValue: ""
  });
  // console.log(selection)

  const onChange = (selection) => {
    field.onChange(selection) // send data back to hook form
    setSelection(selection) // UI state
    // console.log(selection)
  }

  return (
    <div className='sm:col-span-6'>
      <div className="flex items-center justify-between">
        <h2 className="text-sm font-medium text-gray-700">{title}</h2>
      </div>

      <RadioGroup value={selection} onChange={onChange} className="mt-2">
        <RadioGroup.Label className="sr-only"> Choose {title} </RadioGroup.Label>
        <div className="grid grid-cols-4 gap-3 sm:grid-cols-6">
          {optionsList.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  'cursor-pointer focus:outline-none',
                  active ? 'ring-2 ring-offset-2 ring-primary-color-lighter' : '',
                  checked
                    ? 'bg-primary-color border-transparent text-white hover:bg-primary-color-darker'
                    : 'bg-white border-gray-300 text-gray-900 hover:bg-gray-50',
                  'border rounded-md py-2.5 px-3 flex items-center justify-center text-sm font-medium sm:flex-1'
                )
              }
            >
              <RadioGroup.Label as="span">{option}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  )
}
