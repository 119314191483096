import "./App.css";
import Layout from "./components/Layout";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Calendar from "./pages/Calendar";
import React from "react";
import Event from "./pages/EventPage";
import ContactPage from "./pages/ContactPage";

const NavRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route index path="calendar" element={<Calendar />} />
      <Route path="event" element={<Event />} />
      <Route path="contact" element={<ContactPage />} />
    </Routes>
  );
};

function App() {
  return (
    <Layout>
      <NavRoutes />
    </Layout>
  );
}

export default App;
