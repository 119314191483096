import { Link } from "react-router-dom";

const dateFormatter = (date) => {
  let dateString = date.toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  let timeString = Intl.DateTimeFormat("en-US", {
    hour: "numeric",
    minute: "numeric",
  }).format(date);

  return dateString + " at " + timeString;
};

const EventCard = (props) => {
  return (
    <div className="flex flex-col px-4">
      <Link to="/calendar">
        <div className="mb-6 mt-6 font-medium text-primary-color-lighter underline">Return to Calendar</div>
      </Link>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg w-full max-w-3xl">
        <div className="px-4 py-5 bg-primary-color sm:px-6">
          <h3 className="text-xl leading-6 font-medium text-white text-center uppercase">
            {props.title}
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-2">
              <dt className="text-lg font-bold text-primary-color">Date</dt>
              <dd className="mt-1 text-md text-gray-900">
                {dateFormatter(props.date)}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="ext-lg font-bold text-primary-color">
                Description
              </dt>
              <dd className="mt-1 text-md text-gray-900 leading-8">
                {props.description}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
