// If this is moved to typescript can actually use enum
export const TextInputType = {
  TextArea: "textarea",
  Input: "input",
};

export default function TextInput({
  register,
  name,
  options,
  errors,
  type,
  rootClassNames,
  title,
  note,
  ...props
}) {
  return (
    <div className={rootClassNames}>
      <div className="flex justify-between">
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {title} {options?.required === true && "*"}
        </label>
        <span className="text-sm text-red-600">
          {errors !== undefined && errors[name] && errors[name].type}
        </span>
      </div>
      <div className="mt-1">
        {type === TextInputType.TextArea ?
          <textarea
            id={name}
            {...register(name, options)}
            {...props}
            rows={3}
            maxLength="1000"
            className={
              errors !== undefined && errors[name] ?
                "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                : "block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-color focus:border-primary-color sm:text-sm"
            }
          />
          : <input
            id={name}
            type="text"
            {...register(name, options)}
            {...props}
            className={
              errors !== undefined && errors[name] ?
                "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                : "block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-color focus:border-primary-color sm:text-sm"
            }
          />
        }
      </div>
    </div>
  )
}
