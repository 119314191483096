import { NavLink } from "react-router-dom";

const NavButtonMobile = ({ to, text, close }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive ? "bg-primary-color-faintest border-primary-color-lighter text-primary-color block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
          : "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"}
      onClick={async () => { close() }}
    >
      {text}
    </NavLink>
  );
}

export default NavButtonMobile;