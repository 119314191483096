import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import RadioCardsInline from "../components/Forms/RadioCardsInline";
import Section from "../components/Forms/Section";
import TextInput from "../components/Forms/TextInput";
import FormSubmitModal, { FormSubmitModalType } from "../components/Modals/FormSubmitModal";


export default function ContactPage() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  const recaptchaRef = useRef();

  const postFormData = async (event, reCaptchaToken) => {
    return fetch('/api/PostFormSubmissions', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"reCaptchaToken": reCaptchaToken, "event": event}),
    })
  };

  const onSubmit = async (event) => {
    console.log(event);

    // invoke recaptcha challenge programmatically (pops up if sus)
    const reCaptchaToken = await recaptchaRef.current.executeAsync();
    // reset captcha result for next time button is clicked
    recaptchaRef.current.reset();

    const response = await postFormData(event, reCaptchaToken);
    if (response.status >= 200 && response.status <= 299) {
      setShowSuccessModal(true);
    } else {
      setShowFailureModal(true);
    }
  }

  return (
    <div className="px-4 lg:max-w-[1000px] lg:mx-auto ">
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey="6LfZtQQjAAAAAJ5ALfE6RRtSHmVklQPn9LfaHz_b"
      />

      <FormSubmitModal
        isOpen={showSuccessModal}
        setIsOpen={setShowSuccessModal}
        title="Submission successful"
        description="Thank you for contacting us! We will get back with you shortly."
        buttonText="Close"
        type={FormSubmitModalType.Success}
      />
      <FormSubmitModal
        isOpen={showFailureModal}
        setIsOpen={setShowFailureModal}
        title="An error ocurred"
        description="Sorry, something went wrong with submitting. Please reach out to us via email instead."
        buttonText="Close"
        type={FormSubmitModalType.Failure}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="space-y-8 divide-y divide-gray-200">
          <Section
            title="Contact Information"
            description="Let us know who you are and how to reach back to you!"
          >
            <TextInput
              title="Your name / business"
              rootClassNames="sm:col-start-1 sm:col-end-4"
              register={register}
              name="contactName"
              options={{ required: true }}
              errors={errors}
            />

            <TextInput
              title="Email address"
              rootClassNames="sm:col-start-1 sm:col-end-4"
              register={register}
              name="contactEmail"
              options={{ required: true }}
              errors={errors}
            />

            <TextInput
              title="Phone number"
              rootClassNames="sm:col-start-1 sm:col-end-3"
              register={register}
              name="contactPhoneNumber"
              options={{ required: true }}
              errors={errors}
            />

            <TextInput
              title="About yourself"
              rootClassNames="sm:col-span-6"
              type="textarea"
              register={register}
              name="contactAbout"
            />
          </Section>

          <Section
            title="Event Details"
            description="What type of event would you like to host?"
          >
            <TextInput
              title="Event title"
              rootClassNames="sm:col-start-1 sm:col-end-4"
              register={register}
              name="eventTitle"
            />

            <TextInput
              type="textarea"
              title="Event description"
              rootClassNames="sm:col-span-6"
              register={register}
              name="eventDescription"
            />

            <RadioCardsInline
              title="Access"
              optionsList={[
                'Private',
                'Public'
              ]}
              control={control}
              name="eventPublicity"
            />

            <RadioCardsInline
              title="Attendees"
              optionsList={[
                '0-5',
                '6-15',
                '16-25',
                '26-30',
              ]}
              control={control}
              name="eventAttendees"
            />

            <TextInput
              title="Ideal event date and time"
              rootClassNames="sm:col-span-4"
              register={register}
              name="eventDatePrimary"
            />

            <TextInput
              title="Backup event date and time"
              rootClassNames="sm:col-span-4"
              register={register}
              name="eventDateSecondary"
            />
          </Section>

          <Section
            title="Event Setup"
            description="What preperations are needed for the event and is help needed?"
          >
            <TextInput
              type="textarea"
              title="Equipment needed"
              rootClassNames="sm:col-span-6"
              register={register}
              name="eventEquipment"
            />

            <TextInput
              title="Is assistance needed for setup?"
              rootClassNames="sm:col-span-4"
              register={register}
              name="eventAssistance"
            />

            <TextInput
              title="What time is access needed to Pawpaw House for setup?"
              rootClassNames="sm:col-span-4"
              register={register}
              name="eventSetupAccess"
            />
          </Section>

          <div className="py-5">
            <div className="flex justify-start">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-primary-color py-2 px-6 text-md font-medium text-white shadow-sm hover:bg-primary-color-darker focus:outline-none focus:ring-2 focus:ring-primary-color-lighter focus:ring-offset-2"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
