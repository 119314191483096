export const getTodaysDate = () => {
    return (new Date()).setHours(0, 0, 0, 0)
}

export const addIsTodayFlag = (date, today, myDayToAdd) => {
    date.valueOf() === today.valueOf() ? myDayToAdd["isToday"] = true : myDayToAdd["isToday"] = false;
}

export const addIsSelectedFlag = (date, selected, myDayToAdd) => {
    date.valueOf() === selected.valueOf() ? myDayToAdd["isSelected"] = true : myDayToAdd["isSelected"] = false;
}

export const addMyDayToArray = (array, myDayToAdd, method) => {
    method === "push" ? array.push(myDayToAdd) : array.unshift(myDayToAdd)
}

export const addDateToArray = (array, method, date, isCurrentMonth, selectedDay, today) => {
    let myDayToAdd = { date: new Date(date), isCurrentMonth: isCurrentMonth, events: [] }
    addIsTodayFlag(date, today, myDayToAdd)
    addIsSelectedFlag(date, selectedDay, myDayToAdd)
    addMyDayToArray(array, myDayToAdd, method)
}

export const addDaysToPreviousMonth = (array, startOfMonth, selectedDate, todaysDate) => {
    for (let i = 1; i <= (startOfMonth.getDay() === 0 ? 7 : startOfMonth.getDay()); i++) {
        let previousDateToAdd = (new Date(startOfMonth)).setDate(0 - (i - 1))
        addDateToArray(array, "unshift", previousDateToAdd, false, selectedDate, todaysDate)
    }
}

export const addDaysToCurrentMonth = (array, startOfMonth, selectedMonth, selectedDate, todaysDate) => {
    let currentMonthDateToAdd = new Date(startOfMonth)
    while (currentMonthDateToAdd.getMonth() === selectedMonth.getMonth()) {
        addDateToArray(array, "push", currentMonthDateToAdd, true, selectedDate, todaysDate)
        currentMonthDateToAdd.setDate(currentMonthDateToAdd.getDate() + 1)
    }
}

export const addDaysForEndOfMonth = (array, StartOfNextMonth, selectedDate, todaysDate) => {
    let nextMonthDateToAdd = new Date(StartOfNextMonth)
    for (let i = StartOfNextMonth.getDay(); i < 7; i++) {
        addDateToArray(array, "push", nextMonthDateToAdd, false, selectedDate, todaysDate)
        nextMonthDateToAdd.setDate(nextMonthDateToAdd.getDate() + 1)
    }
}

export const parseCreateEventDate = (date) => {
    if (date) {
        let splitDateAndTime = date.split('T')
        let splitDate = splitDateAndTime[0].split('-')
        let splitTime = splitDateAndTime[1].substr(0, splitDateAndTime[1].indexOf('-')).split(':')
        let eventDate = new Date(splitDate[0], (splitDate[1] - 1), splitDate[2], parseInt(splitTime[0]), parseInt(splitTime[1]))
        return eventDate
    }
}

// {title: 'Test 3 Event', description: 'This is a third event', date: '2022-06-09T00:00-04:00'}
export const addEvents = (array, events) => {
    for (let i = 0; i < events.length; i++) {
        let eventDate = parseCreateEventDate(events[i].fields.date)
        events[i].fields["JSDate"] = eventDate

        let evenDateWithoutTime = new Date(eventDate)
        evenDateWithoutTime.setHours(0, 0, 0, 0)

        if (eventDate) {
            let myDayThatMatchesEventDate = array.find((day) => day.date.valueOf() === evenDateWithoutTime.valueOf())
            if (myDayThatMatchesEventDate) {
                let eventWithId = events[i].fields
                eventWithId["id"] = i   // need to add unique ID to stop warning / encounterered bug
                myDayThatMatchesEventDate.events.push(eventWithId)
            }
        }
    }
}

export const unselectedOldDate = (array) => {
    let oldSelectedDay = array.find((day) => day.isSelected === true)
    if (oldSelectedDay != null) {   // if months are change it will be null
        oldSelectedDay["isSelected"] = false
    }
}

export const selectedNewDate = (array, newDay) => {
    let newSelectedDay = array.find((day) => day.date.valueOf() === newDay.date.valueOf())
    newSelectedDay["isSelected"] = true
}