import MonthlyCalendar from "../components/MonthlyCalendar/MonthlyCalendar";
import { CalendarDataContextProvider } from "../contexts/calendarData";


const Calendar = () => {
    return (
        <CalendarDataContextProvider>
            <div className="px-4 lg:px-16 xl:px-32 2xl:px-64">
                <MonthlyCalendar />
            </div>
        </CalendarDataContextProvider>
    )
}

export default Calendar;