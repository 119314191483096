import SectionHeader from "./SectionHeader";


export default function Section({ title, description, children }) {
  return (
    <div className="pt-8">
      <SectionHeader
        title={title}
        description={description}
      />
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        {children}
      </div>
    </div>
  )
}