import React, { useContext, createContext, useState, useCallback } from "react";
import usePopulateDays from "../hooks/usePopulateCalendar";
import { getTodaysDate, selectedNewDate, unselectedOldDate } from "../services/datesHelper";
import getEvents from "../services/getEvents";

const CalendarDataContext = createContext();

const useCalendarData = () => useContext(CalendarDataContext);

const CalendarDataContextProvider = ({ children }) => {
    const todaysDate = getTodaysDate()
    const populateDays = usePopulateDays();     // Hooks must be called from react component or function

    // INFO: Date == Javascript Date object. myDay == json dict that represents a calendar day with events 
    const [events, setEvents] = useState();
    const [selectedMonth, setSelectedMonth] = useState(new Date())
    const [myDays, setMyDays] = useState()
    const [selectedDate, setSelectedDate] = useState(todaysDate)

    const changeMonth = (change) => {
        let newMonth = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth()+change)
        setSelectedMonth(newMonth)
        setMyDays(populateDays(newMonth, selectedDate, todaysDate, events))
    }

    const changeSelectedDate = (myDay) => {
        unselectedOldDate(myDays)
        selectedNewDate(myDays, myDay)
        setSelectedDate(myDay.date)
    }

    const getMyDayFromSelectedDate = () => {
        return myDays.find(((myDay) => myDay.date.valueOf() === selectedDate.valueOf()))
    }

    const fetchEvents = useCallback(() => {
        getEvents()
            .then(rawEvents => {
                setEvents(rawEvents)
                setMyDays(populateDays(selectedMonth, selectedDate, todaysDate, rawEvents))
            })
    }, [populateDays, selectedDate, selectedMonth, todaysDate])
    

    return (
        <CalendarDataContext.Provider value={{ 
                selectedMonth, 
                changeMonth, 
                myDays, 
                changeSelectedDate, 
                getMyDayFromSelectedDate, 
                fetchEvents, 
                events }}>
            {children}
        </CalendarDataContext.Provider>
    );
};

export { CalendarDataContextProvider, useCalendarData };