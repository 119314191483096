import {
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/solid'
import { useCalendarData } from '../../contexts/calendarData'



const MonthlyCalendarHeader = () => {

    const { selectedMonth, changeMonth } = useCalendarData()

    return (
        <header className="relative z-20 flex items-center justify-around  py-4 px-6 lg:flex-none">
            <button
                type="button"
                className="flex items-center justify-center rounded-md bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                onClick={() => changeMonth(-1)}
            >
                <span className="sr-only">Previous month</span>
                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <h1 className="text-lg font-semibold text-[#721513] w-64 text-center">
                <time dateTime={selectedMonth}>
                    {new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long' }).format(selectedMonth)}
                </time>
            </h1>
            <button
                type="button"
                className="flex items-center justify-center rounded-md bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                onClick={() => changeMonth(1)}
            >
                <span className="sr-only">Next month</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
        </header>
    )
}

export default MonthlyCalendarHeader