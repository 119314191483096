import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import NavButtonDesktop from "./NavButtonDesktop";
import NavButtonMobile from "./NavButtonMobile";


const Navbar = () => {
  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between h-16">

              {/* Mobile hamburger / X button */}
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-color-lighter">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                {/* Mobile / Desktop Logo */}
                {/* <div className="flex-shrink-0 flex items-center">
                    <img
                      className="block lg:hidden h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt="Workflow"
                    />
                    <img
                      className="hidden lg:block h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                      alt="Workflow"
                    />
                  </div> */}

                {/* Desktop navbar */}
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                  <NavButtonDesktop to="/" text="Home" />
                  <NavButtonDesktop to="calendar" text="Calendar" />
                  <NavButtonDesktop to="contact" text="Contact" />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile nav panel that pops up when clicking hamburger */}
          <Disclosure.Panel className="sm:hidden">
            {({ close }) => (
              <div className="pt-2 pb-4 space-y-1">
                <NavButtonMobile to="/" text="Home" close={close} />
                <NavButtonMobile to="calendar" text="Calendar" close={close} />
                <NavButtonMobile to="contact" text="Contact" close={close} />
              </div>
            )}
          </Disclosure.Panel>

        </>
      )}
    </Disclosure>
  );
}

export default Navbar;
