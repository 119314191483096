import {
    ClockIcon,
} from '@heroicons/react/24/solid'
import { Link } from 'react-router-dom'
import { useCalendarData } from '../../contexts/calendarData'


const MonthlyCalendarList = () => {
    const { getMyDayFromSelectedDate } = useCalendarData()
    const selectedMyDay = getMyDayFromSelectedDate()
    console.log("MonthlyCalendarList ", selectedMyDay)

    return (
        selectedMyDay?.events.length > 0 && (
            <div className="py-10 px-1 md:hidden">
                <div className="p-2 text-[#721513]">
                    {selectedMyDay.date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
                <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
                    {selectedMyDay.events.map((event) => (
                        <Link to="/event" state={{ myEvent: event }}>
                            <li key={event.id} className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                                <div className="flex-auto">
                                    <p className="font-semibold text-gray-900">{event.title}</p>
                                    <time dateTime={event.date} className="mt-2 flex items-center text-gray-700">
                                        <ClockIcon className="mr-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        {new Intl.DateTimeFormat('en-US', { hour: 'numeric', hour12: true }).format(event.JSDate)}
                                    </time>
                                </div>
                                {/* <a
                                href="{event.href}"
                                className="ml-6 flex-none self-center rounded-md border border-gray-300 bg-white py-2 px-3 font-semibold text-gray-700 opacity-0 shadow-sm hover:bg-gray-50 focus:opacity-100 group-hover:opacity-100"
                            >
                                Edit<span className="sr-only">, {event.name}</span>
                            </a> */}
                            </li>
                        </Link>
                    ))}
                </ol>
            </div>
        )
    )
}

export default MonthlyCalendarList